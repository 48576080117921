import React from 'react';
import { graphql, withPrefix } from 'gatsby';
import styled from '@emotion/styled';
import FunctinosTemplate from 'components/Templates/FunctinosTemplate';
import { FluidObject } from 'gatsby-image';
import { Helmet } from 'react-helmet';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

interface usageAnalysisProps {
  location: {
    pathname: string;
  };
  data: {
    logoNerdFactoryImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    topFunctionImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    imgDashboard: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    imgUserAnalytics: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    imgContents: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    thumbnail: {
      publicURL: string;
    };
  };
}

const usageAnalysis = ({ data, location }: usageAnalysisProps) => {
  const title = 'VODA | 기능 | 웹 사이트 사용 분석';
  const desc =
    '웹 사이트 사용 분석으로 얻은 탄탄한 기초 데이터, 다양한 인사이트를 제공합니다';
  return (
    <Component>
      <Helmet>
        <title>VODA | 기능 | 웹 사이트 사용 분석</title>
        <meta
          name="description"
          content="웹 사이트 사용 분석으로 얻은 탄탄한 기초 데이터, 다양한 인사이트를 제공합니다"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />

        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="<https://voda.nerdfactory.ai/functions/usageAnalysis>"
        />
        <meta property="og:image" content={data.thumbnail.publicURL} />
        <meta property="og:description" content={desc} />
        <meta property="og:site_name" content={title} />
        <meta
          name="naver-site-verification"
          content="062e2e63e40411827d007a80f9f6ca2b16af6702"
        />
        <script src={withPrefix('script.js')} type="text/javascript" />
      </Helmet>
      <FunctinosTemplate
        location={location}
        title={'웹 서비스 사용성 분석'}
        header={{
          main: '고객의 웹 서비스 사용 데이터를\\n수집하고, 명확한 분석을 통해\\n웹 서비스 개선을 위한 다양한\\n인사이트를 제공합니다.',
          sub: '웹 사이트 사용 분석',
        }}
        isAIvory={false}
        functionData={[
          {
            IllustImg: data.imgDashboard.childImageSharp.fluid,
            descData: {
              title:
                '웹 서비스를 사용하는 고객의\\n트래픽을 한눈에 보여줍니다.',
              list: [
                '1. 사용자 수',
                '2. 방문 횟수',
                '3. 평균 페이지뷰 수',
                '4. 평균 이용 시간',
                '5. 웹사이트 사용 분석',
                '6. 사용 환경 분석',
              ],
            },
            engTitle: 'DASHBOARD',
            korTitle: '한눈으로 보는\\n 고객의 웹서비스 사용 정보',
            isBackGround: true,
            isDescLeft: false,
            width: 800,
            key: '0',
          },
          {
            IllustImg: data.imgUserAnalytics.childImageSharp.fluid,
            descData: {
              title:
                '고객의 웹 사이트 사용 시점과\\n페이지 트래픽을 보다 상세하게\\n분석합니다.',
              list: [
                '1. 시간별, 요일별, 주별, 월별 분석',
                '2. 신규 사용자 수 VS 재방문 사용자 수 비교 분석',
                '3. 방문 집중 시간 및 집중 요일 분석',
              ],
            },
            engTitle: 'DETAILS',
            korTitle:
              '고객-방문-페이지 관점으로 분석하는\\n웹 서비스 트래픽 정보',
            isBackGround: false,
            isDescLeft: true,
            width: 700,
            key: '1',
          },
          {
            IllustImg: data.imgContents.childImageSharp.fluid,
            descData: {
              title:
                '고객이 관심있어 하는 콘텐츠를\\n상세하게 분석-시각화해서\\n웹서비스를 더욱 풍성하게 할 수 있는\\n방법을 제시합니다.',
              list: [
                '1. 조회한 메뉴 TOP5 분석',
                '2. 조회한 게시글 TOP5 분석',
                '3. 전체 URL별 페이지뷰 분석',
              ],
            },
            engTitle: 'CONTENTS',
            korTitle: '콘텐츠 이용 분석',
            isBackGround: false,
            isDescLeft: false,
            width: 800,
            key: '2',
          },
        ]}
        img={data.topFunctionImg.childImageSharp.fluid}
        logoNerdFactoryImg={data.logoNerdFactoryImg.childImageSharp.fluid}
      />
    </Component>
  );
};

export default usageAnalysis;

export const queryData = graphql`
  query {
    logoNerdFactoryImg: file(name: { eq: "logo-nerd-factory@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    topFunctionImg: file(name: { eq: "img-top-function@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgDashboard: file(name: { eq: "img-dashboard" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgUserAnalytics: file(name: { eq: "img-userAnalytics-v2" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgContents: file(name: { eq: "img-contents" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    thumbnail: file(name: { eq: "thumbnail-1280-720" }) {
      publicURL
    }
  }
`;
